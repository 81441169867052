import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { BackendService } from '../service/backend.service';
import { PaginationHandler } from '../shared/handler/pagination-handler';
import { PageEvent } from '@angular/material/paginator';
import { cloneDeep } from 'lodash';
import { GenericService } from '../service/generic.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConnectPageComponent } from './dialog-connect-page/dialog-connect-page.component';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
declare var FB: any;
@Injectable({
  providedIn: 'root',
})
export class InstagramCommentsService {
  private appBridge: any;
  sessionToken!: string;
  constructor(private backendService: BackendService, private genericService: GenericService, private dialog: MatDialog, private route: ActivatedRoute, private router: Router) {}

  // App Bridge 초기화
  initializeAppBridge() {
    const urlParams = new URLSearchParams(window.location.search);
    const host = urlParams.get('host');

    if (!host) {
      throw new Error('Host parameter is missing in the URL.');
    }

    const appBridgeConfig = {
      apiKey: '7542d33d80dd7f19060595ad3abc07bc',
      host: host,
    };

    this.appBridge = createApp(appBridgeConfig);
    //console.log('App Bridge initialized with config:', appBridgeConfig);
  }

  // get SessionToken
  getSessionToken(): Observable<string> {
    // console.log('getSessionToken called');
    this.initializeAppBridge();
    return from(
      getSessionToken(this.appBridge)
        .then((token) => {
          // console.log('Session Token:', token);
          this.sessionToken = token;
          return token;
        })
        .catch((error) => {
          console.error('Error getting session token:', error);
          throw error;
        }),
    );
  }

  // verifySessionToken
  verifySessionToken(sessionToken: string): Observable<boolean> {
    const url = 'instagram-comments/shopify/verify';
    // console.log('and token:', sessionToken);
    return this.backendService.select(url, {}, {}, true, sessionToken).pipe(map((response: HttpResponse<any>) => response.body));
  }

  validateShopPlatformId(shopPlatformID: string): Observable<boolean> {
    let url = `${this.baseUrl}/validate`;
    return this.backendService.select(url, { shop_platform_id: shopPlatformID }, {}, true, this.sessionToken).pipe(
      map((response) => {
        this.setShopPlatformID(shopPlatformID);
        return response.body;
      }),
    );
  }

  getQueryParamsString(): Observable<string> {
    return new Observable<string>((observer) => {
      const queryParamsString = window.location.search.substring(1);
      observer.next(queryParamsString);
      observer.complete();
    });
  }

  shopPlatformIDKey = '_sp';
  baseUrl = 'instagram-comments/facebook';

  instagramCommentsAccount!: InstagramCommentsAccount;

  instagramComments: InstagramComment[] | null = null;
  instagramCommentLength!: number;

  imported: boolean = false;
  manageInstagramCommentsLoading: boolean = false;
  searchFor: 'username' | 'content' = 'username';
  searchKeyword: string = '';
  sortBy: 'newest_comments' | 'oldest_comments' | 'newest_posts' | 'oldest_posts' = 'newest_posts';
  isPublished: boolean = false;
  paginationHandler!: PaginationHandler;

  publishedContent: boolean | null = null;

  resetFilter() {
    this.searchFor = 'username';
    this.searchKeyword = '';
    this.sortBy = 'newest_posts';
    this.instagramCommentsAccount.status = InstagramCommentsAccountStatus.NOT_LOGGED_IN;
    this.instagramComments = null;
    this.instagramCommentLength = 0;
  }

  getAccountStatus() {
    let url = `${this.baseUrl}/account`;
    this.backendService.select(url, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken).subscribe((response) => {
      this.instagramCommentsAccount = response.body;

      let statusTrigger =
        this.instagramCommentsAccount.status > InstagramCommentsAccountStatus.NO_PAGE && this.instagramCommentsAccount.status < InstagramCommentsAccountStatus.CONNECTED;

      statusTrigger && this.openDialogConnectPage();

      if (this.instagramCommentsAccount.expired_at === null) return;
      let today = new Date();
      let expiredAt = new Date(this.instagramCommentsAccount.expired_at);
      today >= expiredAt && this.facebookLogout();
    });
  }

  facebookLogin() {
    return new Promise<void>((resolve, reject) => {
      FB.login(
        (response: any) => {
          let authResponse = response.authResponse;
          if (authResponse) {
            let url = `${this.baseUrl}/login`;
            const payload = { facebook_id: authResponse.userID, facebook_access_token: authResponse.accessToken };
            this.backendService.create(url, payload, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken).subscribe({
              next: (response) => {
                this.instagramCommentsAccount = response.body;
                resolve();
              },
              error: (error) => reject(error),
            });
          }
        },
        { scope: 'public_profile,pages_show_list,business_management,instagram_basic,pages_read_engagement,instagram_manage_comments' },
      );
    });
  }

  facebookLogout() {
    let url = `${this.baseUrl}/logout`;
    this.backendService.delete(url, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken).subscribe((response) => {
      this.resetFilter();
      FB.logout();
    });
  }

  getFacebookPages() {
    let url = `${this.baseUrl}/pages`;
    return this.backendService.select(url, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken);
  }

  connectFacebookPage(pageId: string) {
    let url = `${this.baseUrl}/connect-page`;
    this.backendService.create(url, undefined, { shop_platform_id: this.getShopPlatformID(), facebook_page_id: pageId }, {}, true, this.sessionToken).subscribe((response) => {
      this.instagramCommentsAccount = response.body;
      this.instagramCommentsAccount.status === InstagramCommentsAccountStatus.CONNECTED && this.getInitialData().then(() => this.getManageInstagramComments());
    });
  }

  getInitialData() {
    this.imported = true;
    let url = `${this.baseUrl}/initial_data`;
    return new Promise<void>((resolve, reject) => {
      this.backendService.create(url, {}, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken).subscribe({
        next: (response) => response.ok && resolve(),
        error: (err) => reject(err),
      });
    });
  }

  getPublishedInstagramComments() {
    let url = `${this.baseUrl}/is-published`;
    return this.backendService.select(url, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken);
  }

  instagramCommentsParams() {
    return {
      shop_platform_id: this.getShopPlatformID(),
      skip: this.paginationHandler ? this.paginationHandler.skip : 0,
      limit: this.paginationHandler ? this.paginationHandler.limit : 10,
      search_for: this.searchFor,
      search_keyword: this.searchKeyword,
      sort_by: this.sortBy,
      is_published: this.isPublished,
    };
  }

  getInstagramComments(params: {}) {
    let url = `${this.baseUrl}/comments`;
    return this.backendService.select(url, params, {}, true, this.sessionToken);
  }

  getManageInstagramComments(event: PageEvent | undefined = undefined) {
    if (this.manageInstagramCommentsLoading) return;
    this.manageInstagramCommentsLoading = true;

    event && this.updatePaginationOnEvent(event);

    this.getInstagramComments(this.instagramCommentsParams()).subscribe((response) => {
      this.manageInstagramCommentsLoading = false;
      this.instagramComments = response.body.data;
      this.instagramCommentLength = response.body.length;
      this.paginationHandler.paginator.length = this.instagramCommentLength;
      this.imported = false;
    });
  }

  updatePaginationOnEvent(event: PageEvent): void {
    if (event) {
      this.paginationHandler.skip = event.pageSize * event.pageIndex;
      this.paginationHandler.limit = event.pageSize;
    } else {
      this.paginationHandler.skip = 0;
      this.paginationHandler.paginator.firstPage();
    }
  }

  onTogglePublished(comment: InstagramComment, published: boolean) {
    let params = {
      comment_id: comment.id,
      is_published: published,
      shop_platform_id: this.getShopPlatformID(),
    };
    let url = `${this.baseUrl}/comment/publish`;
    this.backendService.create(url, {}, params, {}, true, this.sessionToken).subscribe((response) => {
      if (!response.ok) return;
      comment.is_published = published;
      this.getPublishedInstagramComments().subscribe((response) => (this.publishedContent = response.body));
    });
  }

  getWidgetSettings() {
    let url = `${this.baseUrl}/widget-setting`;
    return this.backendService.select(url, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken);
  }

  updateWidgetSettings(payload: {}) {
    let url = `${this.baseUrl}/widget-setting`;
    return this.backendService.update(url, payload, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken);
  }

  openDialogConnectPage() {
    const dialog = this.dialog.open(DialogConnectPageComponent, { width: '480px', disableClose: true, panelClass: 'connect-page' });
    dialog.afterClosed().subscribe((result) => {
      if (!result) return;
      this.connectFacebookPage(result.facebook_page_id);
    });
  }

  goToThemeEditPage() {
    let url = `${this.baseUrl}/theme-editor`;
    this.backendService.select(url, { shop_platform_id: this.getShopPlatformID() }, {}, true, this.sessionToken).subscribe((response) => {
      let editUrl = response.body;
      window.open(editUrl);
    });
  }

  setShopPlatformID(shopPlatformID: string) {
    sessionStorage.setItem(this.shopPlatformIDKey, shopPlatformID);
  }

  getShopPlatformID() {
    return sessionStorage.getItem(this.shopPlatformIDKey) as any as number;
  }

  get InstagramCommentsAccountStatus(): typeof InstagramCommentsAccountStatus {
    return InstagramCommentsAccountStatus;
  }
}
enum InstagramCommentsAccountStatus {
  NOT_LOGGED_IN = 0,
  NO_PAGE = 100,
  NOT_SELECTED_PAGE = 200,
  NOT_CONNECTED_INSTAGRAM = 300,
  CONNECTED = 400,
}

interface InstagramCommentsAccount {
  status: InstagramCommentsAccountStatus;
  facebook_page_name: string;
  expired_at: Date;
  is_first: boolean;
}

interface InstagramComment {
  id: number;
  instagram_comments_media_id: number;
  instagram_id: number;
  is_published: boolean;
  like_count: number;
  text: string;
  timestamp: Date;
  username: string;
  media_permalink: string;
}
